import React, { useState } from 'react';
import Link from 'gatsby-link'
import SelectLanguage from './SelectLanguage';
import Logo from "../icons/logo.svg";

const Header = (props) => {

  const [isToggled, setIsToggled] = useState(false);
  const toggleBurger = () => setIsToggled(value => !value);
  let navigationClass = '';

  if(isToggled){
    navigationClass = ' active';
  }else{
    navigationClass = '';
  }

  const languageSwicher = (link) => {
    let locale = props.locale === 'de'?'':props.locale + '/';
    return '/' + locale + (link?link:'')
  };

  return (
    <header className="header">
      <div className="constrain constrain--large">
        <div className="header__inner">
          <Link className="logo" title="logo - lopezdesign" to={languageSwicher()}><Logo alt="Logo - lopezdesign" title="logo - lopezdesign"/></Link>
          <Link onClick={(e) => {e.preventDefault(); toggleBurger()}} title="burger - Navigation" className={'burger' + navigationClass} to="/"></Link>
          <div className={'navigation' + navigationClass}>
            <Link title="lopezdesign - Home" to={languageSwicher()}>Home</Link>
            <Link title="lopezdesign - Info" to={languageSwicher('info')}>Info</Link>
            <SelectLanguage langs={props.langs} />
            <a className="mail" href="mailto:mail@lopezdesign.de">mail@lopezdesign.de</a>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header;
